import { Row } from "react-bootstrap";
import RowCardTitle from "./row-card-title";

interface Props {
  children: React.ReactNode;
  className?: string;
  title?: string | React.ReactNode;
}

export default function RowCard({
  children,
  className = '',
  title = '',
  ...props
}: Props) {
  return (<section className={`row-card dropshadow-1 mb-3 ${className}`} {...props}>
    {title && <RowCardTitle title={title} />}
    <Row>
      {children}
    </Row>

    <style global jsx>{`
      .row-card {
        background-color: white;
        padding: 1em;
        border-radius: 1em;
      }
    `}</style>
  </section>)
}