import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";

type Props = {
  label: string;
  variant?: string;
  href?: string;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  disabledExplanation?: string;
  asIcon?: boolean;
  icon?: IconProp;
  block?: boolean;
  isLoading?: boolean;
  style?: React.CSSProperties;
  className?: string,
}

export default function ButtonBase({
  label,
  variant = 'primary',
  href = null,
  onClick = null,
  disabled = false,
  disabledExplanation = 'Resource busy',
  asIcon = false,
  icon = null,
  block = false,
  isLoading = false,
  style = {},
  className = '',
}: Props) {
  function renderButton() {
    return (
      <Button
        variant={variant}
        href={href}
        onClick={onClick}
        disabled={disabled}
        style={disabled ? { ...style, pointerEvents: 'none' } : style}
        className={`${className} ${block ? "w-100 ps-3 text-start d-block mt-1" : ""}`}
      >
        {asIcon ? <FontAwesomeIcon icon={icon} width={13} height={13} /> : label}
        {' '}
        {isLoading && <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />}
      </Button>
    )
  }

  if (!disabled || disabledExplanation === '') {
    return renderButton()
  }

  return (
    <OverlayTrigger
      placement="left"
      overlay={<Tooltip id={'button-disabled-explanation'}>{disabledExplanation}</Tooltip>}
    >
      <span>{renderButton()}</span>
    </OverlayTrigger>
  )
}
