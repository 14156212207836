import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import { useMemo, useState } from 'react';
import FormButton from 'components/form-button'
import ProductsList from 'components/lists/products-list'
import ClaimContainer from 'components/claim-container'
import UpgradeAllButton from 'components/buttons/upgrade-all'
import RowCard from 'components/layout/row-card';
import { useSubscriptions } from 'lib/use-subscriptions';
import { useRouter } from 'next/router';
import { globalConsoleUrl } from 'lib/helpers';
import PricingMigration from 'components/pricing-migration';

export default function Products() {
  const [showMigrateToGlobal, setShowMigrateToGlobal] = useState(false)
  const router = useRouter();

  const { data: subscriptions } = useSubscriptions();
  const globalConsole = useMemo(() => subscriptions != null && subscriptions.every(s => s.managedByGlobal), [subscriptions]);
  const subscription = subscriptions?.find(s => !s.managedByGlobal);

  return (
    <main>
      <Container>
        <Row>
          <Col><h3 className="products-label">{globalConsole ? 'Applications' : 'Products'}</h3></Col>
          <Col>
            <Dropdown className="float-end">
              <Dropdown.Toggle className="btn-product-actions">Actions</Dropdown.Toggle>

              <Dropdown.Menu>
                <FormButton
                  title={globalConsole ? 'Create a new Application' : 'Create a new Product'}
                  modalTitle="Move to new pricing"
                  variant="light"
                  showModal={showMigrateToGlobal}
                  onClick={() => globalConsole ? router.push(`${globalConsoleUrl()}/applications/new`) : setShowMigrateToGlobal(true)}
                  onModalClose={() => setShowMigrateToGlobal(false)}
                  asDropdownItem >
                  <PricingMigration
                    subscriptionId={subscription?.id}
                  ></PricingMigration>
                </FormButton>

                <ClaimContainer claim='UpgradeAll'>
                  <Dropdown.Divider />
                  <UpgradeAllButton
                    resourceType="versions" />
                </ClaimContainer>

                <ClaimContainer claim='UpgradeAll'>
                  <UpgradeAllButton
                    resourceType="tenants" />
                </ClaimContainer>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        <RowCard className="mt-3">
          <Col>
            <ProductsList />
          </Col>
        </RowCard>
      </Container>
    </main>
  )
}