import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Product } from "lib/types";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";

export default function ProductRowDetails({
  product,
}: { product: Product }) {

  return (
    <div >
      {(product.failedVersionCount > 0 || product.failedTenantCount > 0 || product.failedBackupCount > 0) &&
        <ErroredResourceNote id={`error-notice-${product.id}`}>
          {product.failedVersionCount > 0 && <>{product.failedVersionCount} versions in Error state <br /></>}
          {product.failedTenantCount > 0 && <>{product.failedTenantCount} tenants in Error state <br /></>}
          {product.failedBackupCount > 0 && <>{product.failedBackupCount} backups in Error state <br /></>}
        </ErroredResourceNote>
      }

      <small className="text-muted">Versions: {product.versionCount}
        {product.busyVersionCount > 0 && <BusyResourceSpinner id={`versions-${product.id}`}>{product.busyVersionCount} versions busy</BusyResourceSpinner>}
      </small>

      <small className="text-muted">Tenants: {product.tenantCount}
        {product.busyTenantCount > 0 && <BusyResourceSpinner id={`tenant-${product.id}`}>{product.busyTenantCount} tenants busy</BusyResourceSpinner>}
      </small>

      <style jsx>{`
        small {
          font-size: 0.6rem;
          display: inline-block;
          margin-right: 1em;
          min-width: 4rem;
          text-align: left;
        }
      `}</style>
    </div>
  )
}

function BusyResourceSpinner({ id, children }) {
  return (<>

    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`resource-busy-spinner-${id}`}>{children}</Tooltip>}
    >
      <Spinner style={{ marginLeft: "5px" }} as="span" animation="border" size="sm" />
    </OverlayTrigger>
  </>)
}

function ErroredResourceNote({ id, children }) {
  return (<>

    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`resource-failed-icon-${id}`}>{children}</Tooltip>}
    >
      <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faExclamationTriangle} color="orange" height="0.8rem" width="0.8rem" />
    </OverlayTrigger>
  </>)
}