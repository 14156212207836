import React from 'react';
import {
  Modal,
} from 'react-bootstrap';
import ButtonBase from './buttons/button-base';
import DropdownItemButtonBase from './buttons/dropdown-item-button-base';

export default function FormButton({
  onModalClose = () => { },
  onClick = () => { },
  title,
  modalTitle = title,
  variant = 'primary',
  disabled = false,
  disabledExplanation = '',
  asDropdownItem = false,
  showModal = false,
  children,
  block = false,
  asIcon = false,
  icon = null,
  className=''
}) {
  return <>
    {asDropdownItem && <DropdownItemButtonBase
      title={title}
      variant={variant}
      onClick={() => onClick()}
      className={className}
      disabled={disabled}
      disabledExplanation={disabledExplanation}
    />
    }
    {!asDropdownItem && <ButtonBase
      label={title}
      variant={variant}
      onClick={() => onClick()}
      disabled={disabled}
      block={block}
      disabledExplanation={disabledExplanation}
      asIcon={asIcon}
      icon={icon}
      className={className}
    />}

    <Modal show={showModal} onHide={() => onModalClose()} centered>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  </>
}
