import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import useProductList from 'lib/use-product-list'
import { Form, InputGroup, Table } from 'react-bootstrap'
import ProductRowDetails from 'components/product-row-details'
import ResourceStatusBadge from 'components/resource-status-badge'
import InternalLink from 'components/internal-link'
import { useRouter } from 'next/router'
import { getSubscriptionUrl } from 'lib/helpers'
import { useSubscriptions } from 'lib/use-subscriptions'
import LoadingIndicator from 'components/loading-indicator'

export default function ProductsList({
}) {
  const router = useRouter();

  // Keep the ?subscriptionId query parameter working, but refactor it to ?q=
  if (router.query != null && router.query.subscriptionId != null) {
    const currentRoute = router.asPath.substring(0, router.asPath.indexOf("?"));
    const newQuery = `?q=${router.query.subscriptionId}`;
    router.replace(`${currentRoute}${newQuery}`, undefined, { shallow: true });
  }

  const query = router.query != null ? router.query.q as string : null;

  const { data, isValidating: productsValidating } = useProductList()
  const { data: subscriptionList } = useSubscriptions();
  const [productsPerSubscription, setProductsPerSubscription] = useState({})
  const [subscriptions, setSubscriptions] = useState([])
  const [searchValue, setSearchValue] = useState(query)
  const [uniqueSubscription, setUniqueSubscription] = useState(false);

  useEffect(() => {
    if (data) {
      const result = [];
      const map = new Map();
      for (const product of data) {
        if (!map.has(product.subscriptionId)) {
          map.set(product.subscriptionId, true);
          result.push({
            id: product.subscriptionId
          });
        }
      }
      setUniqueSubscription(!(result.length > 1));

      const filteredData = query == null || query.length == 0 ? data : data.filter((product) => {
        return product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
          || product.subscriptionName.toLowerCase().indexOf(query.toLowerCase()) !== -1
          || product.subscriptionId.toLowerCase().startsWith(query.toLowerCase());
      });

      // Distinct and sorted subscriptions
      const subscriptions = filteredData
        .filter((product, i, a) => a.findIndex(fp => fp.subscriptionId === product.subscriptionId) === i)
        .map(product => ({
          subscriptionName: product.subscriptionName,
          subscriptionId: product.subscriptionId,
        }))
        .sort((a, b) => a.subscriptionName.localeCompare(b.subscriptionName))
      setSubscriptions(subscriptions);

      // [{product},{product}, ...] => {subId: product, ...}
      const prodsPerSub = filteredData
        .sort((a, b) => a.name.localeCompare(b.name))
        .reduce((agg, cv) => ({
          ...agg,
          [cv.subscriptionId]: [...(agg[cv.subscriptionId] || []), cv],
        }), {});
      setProductsPerSubscription(prodsPerSub);
    }
  }, [data, query]);

  const filterBySearch = (event) => {
    setSearchValue(event.target.value);
    const currentRoute = router.asPath.substring(0, router.asPath.indexOf("?"));
    const query = event.target.value != null && event.target.value.length > 0 ? `?q=${event.target.value}` : "";
    router.replace(`${currentRoute}${query}`, undefined, { shallow: true });
  };

  return <div className='product-list'>
    {subscriptions.length >= 0 &&
      <InputGroup>
        <Form.Control
          placeholder='Search by product or subscription'
          type="text"
          onChange={filterBySearch}
          value={searchValue}
        />
      </InputGroup>
    }
    <Table hover responsive>
      <thead>
        <tr>
          <th className="border-0">Name</th>
          <th className="border-0"></th>
        </tr>
      </thead>
      <tbody>
        {subscriptions && subscriptions.map(subscription => (
          <React.Fragment key={subscription.subscriptionId}>
            {(subscriptions.length > 1 || !uniqueSubscription) &&
              <tr className="subscription-row">
                <td colSpan={2}>
                  <InternalLink href={getSubscriptionUrl(subscriptionList?.find(sub => sub.id === subscription.subscriptionId), subscription.subscriptionId)}>
                    <b>{subscription.subscriptionName}</b>
                  </InternalLink>
                </td>
              </tr>
            }
            {productsPerSubscription[subscription.subscriptionId] && productsPerSubscription[subscription.subscriptionId].map(product => (
              <tr
                key={product.id}
              >
                <td className="product-list-product-link">
                  <Link href={`/products/${product.id}`}>{product.name}</Link>
                </td>
                <td className="text-end d-flex justify-content-end">
                  <ResourceStatusBadge resource={product} className="me-2" />
                  {' '}
                  <ProductRowDetails product={product} />
                </td>
              </tr>
            ))}
          </React.Fragment>
        ))}
        {subscriptions?.length === 0 && !productsValidating &&
          <tr>
            <td colSpan={2} className="text-center">
              No products to show yet. Use <i>the blue action button</i> to get started on a new product...
            </td>
          </tr>
        }
        {subscriptions?.length === 0 && productsValidating &&
          <tr>
            <td colSpan={2} className="text-center">
              <LoadingIndicator />
            </td>
          </tr>
        }
      </tbody>
    </Table>

    <style global jsx>{`
      .subscription-row {
        background: rgba(0, 0, 0, .04);
        color: rgba(0, 0, 0, .5);
        font-size: 0.9rem;
      }

      .subscription-row td {
        padding-top: 0.3em;
        padding-bottom: 0.3em;
      }
    `}</style>
  </div>
}
