import {
  Row,
  Col,
  Button,
  Spinner,
  Alert,
} from 'react-bootstrap';
import { ReactNode, useState } from 'react';


declare interface IConfirmFormParams {
  bodyText?: string | ReactNode,
  cancelButtonText?: string,
  submitButtonText?: string,
  submitButtonVariant?: string,
  onSubmitted?: () => void,
  onCancel?: () => void,
  url: string,
  method?: 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  requestBody?: any
}

export default function ConfirmForm({
  bodyText = 'Are you sure?',
  cancelButtonText = 'Cancel',
  submitButtonText = 'Submit',
  submitButtonVariant = 'primary',
  onSubmitted = () => { },
  onCancel = () => { },
  url,
  method = 'POST',
  requestBody = {}
}: IConfirmFormParams) {

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [responseError, setResponseError] = useState(null)

  const onSubmit = () => {
    setIsSubmitting(true);
    return fetch(`${url}`, {
      method: method,
      body: JSON.stringify(requestBody)
    }).then((response) => {
      if (response.ok) {
        onSubmitted();
      } else {
        response.json().then((data) => setResponseError(data.message))
          .catch(() => setResponseError(response.statusText))
      }
    })
      .catch((err) => setResponseError(err.message))
      .finally(() => setIsSubmitting(false))
  }

  return <>
    <Row><Col>{bodyText}</Col></Row>

    {responseError &&
      <>
        <Row>
          <Col>
            <Alert variant='danger'>Something went wrong while processing the request: {responseError}</Alert>
          </Col>
        </Row>
      </>
    }

    <hr />
    <Row>
      <Col className="text-end">
        <Button variant="secondary" className="me-3" onClick={() => onCancel()}>
          {cancelButtonText}
        </Button>
        <Button variant={submitButtonVariant} type="submit" onClick={() => onSubmit()}>
          {submitButtonText}
          {' '}
          {isSubmitting && <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />}
        </Button>
      </Col>
    </Row>
  </>
}
