import FormButton from 'components/form-button';
import React, { useState } from 'react';
import ConfirmForm from 'components/forms/confirm-form';

export default function UpgradeAllButton({
  resourceType
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <FormButton
        title={`Upgrade all ${resourceType}`}
        modalTitle="Are you really sure?"
        variant="danger"
        showModal={showModal}
        onClick={() => setShowModal(true)}
        onModalClose={() => setShowModal(false)}
        asDropdownItem
      >
        <ConfirmForm
          url={`/api/v1/upgrade/${resourceType}`}
          submitButtonText="I'm sure"
          submitButtonVariant="danger"
          bodyText={`Are you really really sure you want to upgrade ALL ${resourceType}?`}
          onSubmitted={() => setShowModal(false)}
          onCancel={() => setShowModal(false)}
        />
      </FormButton>
    </>
  )
}
