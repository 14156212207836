import {
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

export default function DropdownItemButtonBase({
  onClick = () => { },
  title,
  variant = 'primary',
  disabled = false,
  className = '',
  disabledExplanation = "This action is disabled",
}) {
  return <>
    {disabled &&

      <OverlayTrigger
        placement="left"
        overlay={<Tooltip id={`disabled-explanation-${title}`}>{disabledExplanation}</Tooltip>}
      >
        <span>
          <Dropdown.Item
            className={className}
            variant={variant}
            onClick={() => onClick()}
            disabled={disabled}
          >
            {title}
          </Dropdown.Item>
        </span>
      </OverlayTrigger>
    }

    {!disabled &&
      <Dropdown.Item
        variant={variant}
        className={className}
        onClick={() => onClick()}
      >
        {title}
      </Dropdown.Item>
    }
  </>
}
