import { Badge, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'

export default function ResourceStatusBadge({
  resource,
  className = ''
}) {
  switch (resource.status.statusCode) {
    case 0:
      return (
        <Badge pill bg="primary" className={className}>
          <span className="me-1">Busy</span>
          <Spinner animation="border" role="status" size="sm" />
        </Badge>
      )
    case 1:
      return null
    case 2:
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`resource-status-${resource.id}`}>{resource.status.message || 'Unknown error'}</Tooltip>}
        >
          <Badge pill bg="warning" className={className}>
            <span>Error</span>
          </Badge>
        </OverlayTrigger>
      )
    case 3:
      return (
        <Badge pill bg="danger" className={className}>
          <span className="me-1">Removing</span>
          <Spinner animation="border" role="status" size="sm" />
        </Badge>
      )
    default:
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`resource-status-${resource.id}`}>{resource.status.message || 'Unknown status'}</Tooltip>}
        >
          <Badge pill bg="warning" className={className}>
            <span>Uknown</span>
          </Badge>
        </OverlayTrigger>
      )
  }
}
