import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function LoadingIndicator() {
  return (
    <div className="w-100 h-100 text-center">
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </div>
  )
}