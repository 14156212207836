import { migrationUrl } from "lib/helpers";
import { Button, Col } from "react-bootstrap";

export default function PricingMigration({
  subscriptionId,
}) {

  return <>
    <p>Unfortunately Product creation has been disabled for our legacy pricing model.</p>
    <p>To create new applications please upgrade your subscription to our new pricing model and enjoy benefits like consolidated invoicing and more control over your resources.</p>
    <Col className="text-end">
      <Button href={migrationUrl(subscriptionId)}>
        Move to new pricing
      </Button>
    </Col>
  </>
}