import { Col, Row } from "react-bootstrap";

interface Props {
  title?: string | React.ReactNode;
  children?: React.ReactElement;
  opacity?: number;
}

export default function RowCardTitle({
  title,
  children,
  opacity = 0.7,
}: Props) {
  if (!title && !children) {
    return null;
  }

  return (<>
    <Row className="mb-2">
      <Col><h4 className="row-card-title">{title || children}</h4></Col>
    </Row>

    <style jsx>{`
      .row-card-title {
        font-weight: 300;
        opacity: ${opacity};
        font-size: 1.3em;
      }
    `}</style>
  </>)
}